<template>
  <div class="profile">
        <div class="profile__banner primary">
            MI PERFIL
        </div>
        <div class="profile__password">
            <h2 class="profile__password__title"><v-icon class="profile__password__title-icon">mdi-lock</v-icon> Cambiar contraseña</h2>
            <hr class="profile__password-separator">
            <validation-observer
                ref="observer"
            >
                <form v-if="!success" class="profile__password__form" @submit.prevent="changePassword" novalidate>
                    <v-container>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Nueva contraseña"
                            :rules="{
                                required: true,
                                regex: '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$'
                            }"
                        >
                            <v-text-field
                                    label="Nueva contraseña"
                                    outlined
                                    v-model="password"
                                    :error-messages="errors"
                                    type="password"
                                    dense
                                    required
                                ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Repetir contraseña"
                            :rules="{
                                required: true,
                                samePass: password
                            }"
                        >
                            <v-text-field
                                label="Repetir contraseña"
                                outlined
                                v-model="repeatPassword"
                                :error-messages="errors"
                                type="password"
                                dense
                                required
                            ></v-text-field>
                        </validation-provider>
                        <p v-if="errorMsg != null" class="profile__password__form-error">{{ errorMsg }}</p>
                        <div class="profile__password__form-button">
                            <v-btn
                                depressed
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                >
                                Cambiar contraseña
                            </v-btn>
                        </div>
                    </v-container>
                </form>
                <div v-else>
                    <p class="mb-1">
                        <b>¡Tu contraseña se ha actualizado correctamente!</b>
                    </p>
                    <p>
                        Recuerda que a partir de ahora deberás utilizar esta nueva contraseña cada vez que inicies sesión.
                    </p>
                </div>
            </validation-observer>
        </div>
  </div>
</template>

<script>
import { required, regex } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
import httpService from '@/services/httpService'

setInteractionMode('eager');

extend('required', {
    ...required,
    message: 'El campo es requerido',
  });

extend('regex', {
    ...regex,
    message: 'La contraseña debe tener como mínimo 8 caracteres y debe incluir al menos una mayúscula, una minúscula y un número',
});

const samePass = (value, password) => {
  return value == String(password);
};

extend('samePass', samePass, 'password');

extend('samePass', {
    message: 'Las contraseñas deben coincidir'
});

export default {
  name: 'Profile',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
        loading: false,
        password: null,
        repeatPassword: null,
        errorMsg: null,
        success: false
    }
  },
  methods: {
    changePassword() {
        this.errorMsg = null;
        this.loading = true;
        this.$refs.observer.validate().then((valid) => {
            if (valid) {
                httpService.post('/auth/reset-password', {
                    password: this.password
                }).then(() => {
                    this.success = true;
                    this.loading = false;
                }).catch(() => {
                    this.errorMsg = 'Se ha producido un error al actualizar la contraseña. Inténtalo de nuevo en unos minutos.';
                    this.loading = false;
                })
            } else {
                this.loading = false;
            }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/variables';

    .profile {
        margin-top: $header_auth_size_mobile;
        color: $text_color;
        width: 100%;
        font-weight: 400;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 960px) { 
            margin-top: $header_auth_size_desktop;
        }
        &__banner {
            padding: 20px;
            color: white;
            text-align: center;
            font-size: 18px;
            margin-bottom: 10px;
            @media (min-width: 960px) { 
                margin-bottom: 30px;
                font-size: 22px;
            }
        }
        &__password {
            margin: 20px auto;
            width: 95%;
            max-width: 600px;
            &__title {
                &-icon {
                    vertical-align: baseline;
                }
            }
            &-separator {
                border-bottom: none;
                border-color: #eee;
                margin: 5px 0 10px 0;
            }
            &__form {
                padding: 0px 20px 20px 20px;
                &-button {
                    display: flex;
                    button {
                        margin: auto;
                    }
                }
                &-error {
                    color: #f44336;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
    }
</style>
